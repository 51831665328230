// Name:            Print
// Description:     Optimize page for printing
//
// Adapted from http://github.com/h5bp/html5-boilerplate
//
// Modifications:   Removed link `href` and `title` related rules
//
// ========================================================================


/* ========================================================================
   Component: Print
 ========================================================================== */

@media print {

    *,
    *::before,
    *::after {
        background: transparent !important;
        color: black !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited { text-decoration: underline; }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead { display: table-header-group; }

    tr,
    img { page-break-inside: avoid; }

    img { max-width: 100% !important; }

    @page { margin: 0.5cm; }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 { page-break-after: avoid; }

   @if(mixin-exists(hook-print)) {@include hook-print();}

}

// Hooks
// ========================================================================

// @mixin hook-print(){}

// Name:            Link
// Description:     Styles for links
//
// Component:       `uk-link-muted`
//                  `uk-link-text`
//                  `uk-link-heading`
//                  `uk-link-reset`
//
// Sub-objects:     `uk-link-toggle`
//
// ========================================================================


// Variables
// ========================================================================

$link-muted-color:                               $global-muted-color !default;
$link-muted-hover-color:                         $global-color !default;

$link-text-hover-color:                          $global-muted-color !default;

$link-heading-hover-color:                       $global-primary-background !default;
$link-heading-hover-text-decoration:             none !default;


/* ========================================================================
   Component: Link
 ========================================================================== */


/* Muted
 ========================================================================== */

a.uk-link-muted,
.uk-link-muted a {
    color: $link-muted-color;
    @if(mixin-exists(hook-link-muted)) {@include hook-link-muted();}
}

a.uk-link-muted:hover,
.uk-link-muted a:hover,
.uk-link-toggle:hover .uk-link-muted,
.uk-link-toggle:focus .uk-link-muted {
    color: $link-muted-hover-color;
    @if(mixin-exists(hook-link-muted-hover)) {@include hook-link-muted-hover();}
}


/* Text
 ========================================================================== */

a.uk-link-text,
.uk-link-text a {
    color: inherit;
    @if(mixin-exists(hook-link-text)) {@include hook-link-text();}
}

a.uk-link-text:hover,
.uk-link-text a:hover,
.uk-link-toggle:hover .uk-link-text,
.uk-link-toggle:focus .uk-link-text {
    color: $link-text-hover-color;
    @if(mixin-exists(hook-link-text-hover)) {@include hook-link-text-hover();}
}


/* Heading
 ========================================================================== */

a.uk-link-heading,
.uk-link-heading a {
    color: inherit;
    @if(mixin-exists(hook-link-heading)) {@include hook-link-heading();}
}

a.uk-link-heading:hover,
.uk-link-heading a:hover,
.uk-link-toggle:hover .uk-link-heading,
.uk-link-toggle:focus .uk-link-heading {
    color: $link-heading-hover-color;
    text-decoration: $link-heading-hover-text-decoration;
    @if(mixin-exists(hook-link-heading-hover)) {@include hook-link-heading-hover();}
}


/* Reset
 ========================================================================== */

/*
 * `!important` needed to override inverse component
 */

a.uk-link-reset,
.uk-link-reset a {
    color: inherit !important;
    text-decoration: none !important;
    @if(mixin-exists(hook-link-reset)) {@include hook-link-reset();}
}


/* Toggle
 ========================================================================== */

.uk-link-toggle {
    color: inherit !important;
    text-decoration: none !important;
}
 
.uk-link-toggle:focus { outline: none; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-link-misc)) {@include hook-link-misc();}

// @mixin hook-link-muted(){}
// @mixin hook-link-muted-hover(){}
// @mixin hook-link-text(){}
// @mixin hook-link-text-hover(){}
// @mixin hook-link-heading(){}
// @mixin hook-link-heading-hover(){}
// @mixin hook-link-reset(){}
// @mixin hook-link-misc(){}


// Inverse
// ========================================================================

$inverse-link-muted-color:                         $inverse-global-muted-color !default;
$inverse-link-muted-hover-color:                   $inverse-global-color !default;
$inverse-link-text-hover-color:                    $inverse-global-muted-color !default;
$inverse-link-heading-hover-color:                 $inverse-global-primary-background !default;



// @mixin hook-inverse-link-muted(){}
// @mixin hook-inverse-link-muted-hover(){}
// @mixin hook-inverse-link-text-hover(){}
// @mixin hook-inverse-link-heading-hover(){}

//
// Component:       Mixin
// Description:     Defines mixins which are used across all components
//
// ========================================================================


// SVG
// ========================================================================

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin svg-fill($src, $color-default, $color-new){

    $replace-src: str-replace($src, $color-default, $color-new) !default;
    $replace-src: str-replace($replace-src, "#", "%23");
    background-image: url(quote($replace-src));
}
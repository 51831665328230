a {
  color: $primary-color-second;
  &:hover {
    text-decoration: none;
  }
}

ol {
  padding-left: 16px;
}

.mp-remove > * {
  margin: 0;
  padding: 0;
}

.fz20 {
  font-size: 20px;
}

.fz16 {
  font-size: 16px;
}

.fz12 {
  font-size: 12px;
}

.fwt {
  font-weight: $font-weight-thin;
}

.gray-c {
  color: $default-color-third;
}

.light-gray-c {
  color: $default-color-fifth;
}

.black-c {
  color: $default-color-first;
}

.primary-c {
  color: $primary-color-second;
}

.white-c {
  color: $default-color-second;
}

.box-shadow {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.bg-primary-dark {
  background: $primary-color-first;
}

.bg-gray {
  background: $bg-color-first;
}

.height100 {
  height: 100%;
}

.around32 {
  padding: 32px;
  @media screen and (max-width: 960px) {
    padding: 32px 16px;
  }
}

.fit-cover {
  object-fit: cover;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $default-color-first;
  span {
    display: block;
    max-width: 210px;
    margin-left: 8px;
    text-transform: uppercase;
  }
  &:hover {
    text-decoration: none;
    color: $default-color-first;
  }
  @media screen and (max-width: 960px) {
    .head & img {
      max-width: 40px;
    }
    &.offcanvas-logo {
      max-width: 40px;
    }
  }
}

.head_main {
  padding: 24px 0;
  @media screen and (max-width: 960px) {
    padding: 16px 0;
  }
}

.head_nav {
  background: $primary-color-first;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  li {
    line-height: 48px;
    list-style-type: none;
    position: relative;
    transition: .3s ease-in-out;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -16px;
      z-index: 0;
      width: 100%;
      height: 100%;
      transition: .3s ease-in-out;
      padding: 0 16px;
      box-sizing: unset;
    }
    &:hover {
      &:after {
        background: $primary-color-second;
      }
    }
  }
  a {
    display: block;
    color: $default-color-second;
    position: relative;
    z-index: 1;
  }
}

.link-with-icon {
  display: flex;
  align-items: center;
  color: $default-color-first;
  &:hover {
    color: $primary-color-second;
    text-decoration: none;
  }
}

.footer {
  padding-top: 48px;
  background: $bg-color-first;
  &_copyright {
    padding: 32px 0;
    font-size: 12px;
    border-top: 1px solid $stroke-color-first;
    margin-top: 32px;
    @media screen and (max-width: 639px) {
      font-weight: $font-weight-thin;
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    a {
      color: $default-color-first;
      &:hover {
        color: $primary-color-second;
      }
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.page-banner {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding: 24px 0 48px;
  min-height: max-content;
  h1 {
    color: $default-color-second;
    margin: 0;
  }
  @media screen and (max-width: 639px) {
    padding: 24px 0 24px;
  }
}

.breadcrumb-wrapper {
  height: 25px;
  overflow: hidden;
  margin-bottom: 16px !important;
}

.blockquote {
  background: url("../img/temp/nii1.jpg") no-repeat 50% 50% / cover;
  padding: 48px 32px;
  &-section {
    @media screen and (max-width: 639px) {
      .uk-container {
        padding: 0;
      }
    }
  }
  &-inner {
    z-index: 1;
    position: relative;
    padding: 64px 48px;
    background: rgba(255, 255, 255, .9);
    &::before {
      z-index: 2;
      content: url("../img/icons/cow.svg");
      position: absolute;
      top: 56px;
      left: 48px;
    }
    p {
      margin: 0;
      font-size: 16px;
      position: relative;
      z-index: 3;
    }
  }
  &-author {
    position: relative;
    padding-top: 32px;
    margin-top: 32px;
    &-name {
      font-size: 16px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 1px;
      width: 50%;
      background: $default-color-first;
    }
  }
  @media screen and (max-width: 639px) {
    padding: 48px 16px;
    &-inner {
      padding: 48px 16px;
      &::before {
        top: 40px;
        left: 16px;
      }
      p {
        font-size: 14px;
      }
    }
    &-author {
      padding-top: 16px;
      margin-top: 16px;
      &-name {
        font-size: 14px;
      }
    }
  }
}

.books-tab {
  > li {
    margin-right: 32px;
  }
}

.workers-tab {
  @media screen and (max-width: 639px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.nav-item {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
    will-change: transform;
    transition: all .3s ease;
  }
  &::before {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(13, 71, 161, 0) 0%, #0D47A1 100%);
  }
  &-title {
    position: absolute;
    z-index: 3;
    bottom: 32px;
    left: 0;
    width: 100%;
    text-align: center;
    color: $default-color-second;
    font-size: 20px;
    padding: 0 16px;
  }
  @media screen and (max-width: 959px) {
    &-title {
      bottom: 16px;
      font-size: 14px;
    }
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.index-slide {
  position: relative;
  z-index: 1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: max-content;
  height: 550px;
  color: $default-color-second;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 24px;
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
  }
  > * {
    position: relative;
    z-index: 3;
  }
  @media screen and (max-width: 639px) {
    padding: 48px 16px;
    height: calc(100vh - 72px);
  }
}

.calendar {
  &-slide {
    &-link {
      color: $default-color-second;
      transition: .3s ease-in-out;
      &:hover {
        color: $primary-color-third;
      }
    }
    padding: 48px 32px;
    box-sizing: border-box;
    background: url("../img/temp/pattern.svg") no-repeat $primary-color-first;
    background-position: right center;
    background-size: contain;
    &-head {
      display: flex;
      justify-content: flex-start;
      color: #C0CBE0;
      position: relative;
      .calendar-slider-numb {
        font-size: 6.67em;
        line-height: 80px;
        font-weight: 700;
        margin-right: 8px;
      }
      .calendar-slider-month {
        text-transform: uppercase;
        margin-right: 8px;
        font-weight: 600;
      }
      .calendar-slider-weekday {
        text-transform: uppercase;
      }
    }
    &-main {
      margin: 64px 0;
      h3 {
        font-weight: 700;
        font-size: 24px;
        color: #fff;
      }
    }
    &-footer {
      p {
        color: #fff;
        font-weight: 700;
        margin: 0;
      }
      p:first-child {
        color: #C0CBE0;
        opacity: .5;
        font-weight: 700;
      }
    }
    @media screen and (max-width: 959px) {
      background: $primary-color-first;
    }
    @media screen and (max-width: 639px) {
      padding: 32px 16px;
      &-main {
        margin: 32px 0;
        h3 {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
}

.calendar-slider-count {
  height: 0;
  width: 0;
  opacity: 0;
  margin: 0;
}

.calendar-slider-counter {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  color: $primary-color-first;
  font-weight: 600;
}

.calendar .uk-slidenav {
  padding: 0;
}

.calendar-slider-wrapper {
  position: relative;
}

#offcanvas-menu {
  a {
    color: $default-color-first;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  nav {
    margin-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $stroke-color-first;

    li {
      margin-bottom: 16px;
    }
  }
  .offcanvas-footer {
    padding-top: 32px;
    text-align: center;
    border-top: 1px solid $stroke-color-first;
    a {
      color: $primary-color-second;
    }
  }
  .link-with-icon {
    justify-content: center;
    &:hover {
      color: $primary-color-second;
    }
  }
}
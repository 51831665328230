// Name:            Tooltip
// Description:     Component to create tooltips
//
// Component:       `uk-tooltip`
//
// Modifiers        `uk-tooltip-top`
//                  `uk-tooltip-top-left`
//                  `uk-tooltip-top-right`
//                  `uk-tooltip-bottom`
//                  `uk-tooltip-bottom-left`
//                  `uk-tooltip-bottom-right`
//                  `uk-tooltip-left`
//                  `uk-tooltip-right`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$tooltip-z-index:                                $global-z-index + 30 !default;
$tooltip-max-width:                              200px !default;
$tooltip-padding-vertical:                       3px !default;
$tooltip-padding-horizontal:                     6px !default;
$tooltip-background:                             #666 !default;
$tooltip-border-radius:                          2px !default;
$tooltip-color:                                  $global-inverse-color !default;
$tooltip-font-size:                              12px !default;

$tooltip-margin:                                 10px !default;


/* ========================================================================
   Component: Tooltip
 ========================================================================== */

/*
 * 1. Hide by default
 * 2. Position
 * 3. Dimensions
 * 4. Style
 */

.uk-tooltip {
    /* 1 */
    display: none;
    /* 2 */
    position: absolute;
    z-index: $tooltip-z-index;
    /* 3 */
    box-sizing: border-box;
    max-width: $tooltip-max-width;
    padding: $tooltip-padding-vertical $tooltip-padding-horizontal;
    /* 4 */
    background: $tooltip-background;
    border-radius: $tooltip-border-radius;
    color: $tooltip-color;
    font-size: $tooltip-font-size;
    @if(mixin-exists(hook-tooltip)) {@include hook-tooltip();}
}

/* Show */
.uk-tooltip.uk-active { display: block; }


/* Direction / Alignment modifiers
 ========================================================================== */

/* Direction */
[class*='uk-tooltip-top'] { margin-top: (-$tooltip-margin); }
[class*='uk-tooltip-bottom'] { margin-top: $tooltip-margin; }
[class*='uk-tooltip-left'] { margin-left: (-$tooltip-margin); }
[class*='uk-tooltip-right'] { margin-left: $tooltip-margin; }


// Hooks
// ========================================================================

@if(mixin-exists(hook-tooltip-misc)) {@include hook-tooltip-misc();}

// @mixin hook-tooltip(){}
// @mixin hook-tooltip-misc(){}

*, ::before, ::after {
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   outline: none;
 }

//section
@mixin hook-section-misc {
  .uk-section {
    @media screen and (min-width: 960px) {
      padding-top: 48px;
      padding-bottom: 80px;
      &.uk-section-large {
        padding: 80px 0;
      }
    }
    @media screen and (max-width: 959px) {
      padding: 48px 0 48px;
    }
  }
}

$section-large-padding-vertical: 80px;
$section-large-padding-vertical-m: 80px;

//margin
$margin-small-margin: 8px;
$margin-margin: 16px;
$margin-medium-margin: 24px;
$margin-large-margin: 24px;
$margin-large-margin-l: 32px;
$margin-xlarge-margin: 32px;
$margin-xlarge-margin-l: 48px;

//grid gutter
$grid-gutter-vertical: 24px;
$grid-gutter-vertical-l: 24px;
$grid-gutter-horizontal: 24px;
$grid-gutter-horizontal-l: 24px;
$grid-small-gutter-horizontal: 24px;

// body
$base-body-font-family: $font-family;
$base-body-font-size: $font-size;
$base-body-color: $default-color-first;

// heading
$base-heading-color: $default-color-first;
$base-heading-font-family: $font-family;
$base-heading-font-weight: $font-weight-regular;
$base-heading-color: $inherit;
$base-h1-font-size-m: 32px;
$base-h1-font-size: 24px;
$base-h2-font-size-m: 24px;
$base-h2-font-size: 20px;
$base-h3-font-size-m: 20px;
$base-h3-font-size: 16px;
$base-h4-font-size: 16px;
$base-h5-font-size: 14px;
$base-h6-font-size: 12px;

//button
@mixin hook-button {
  padding: 0 16px;
  border-radius: 2px;
  &.with-arrow {
    display: flex;
    align-items: center;
    max-width: max-content;
  }
}

//button primary
$button-primary-background: $primary-color-second;
@mixin hook-button-primary {
  box-shadow: inset 0px -1px 1px #083156;
}

//button default
$button-default-color: $primary-color-second;
@mixin hook-button-default {
  border: 1px solid $primary-color-second;
}

//breaadcrumb
$breadcrumb-item-color: $default-color-fourth;
$breadcrumb-item-active-color: $default-color-second;
$breadcrumb-divider-color: $default-color-fourth;
$breadcrumb-item-hover-color: $default-color-second;

@mixin hook-breadcrumb {
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 30px;
}

//pagination
$pagination-item-color: $default-color-first;
$pagination-item-active-color: $primary-color-second;

//dotnav
$dotnav-item-border: $primary-color-second;
$dotnav-item-active-background: $primary-color-second;

@mixin hook-dotnav {
  &.dotnav-white>*>* {
    border-color: $default-color-second;
  }
  &.dotnav-white>.uk-active>* {
    background-color: $default-color-second;
  }
}

//tabs
$tab-item-padding-vertical: 0;
$tab-item-padding-horizontal: 0;
$tab-item-color: $default-color-third;
$tab-item-active-color: $primary-color-first;
$tab-border: unset;

@mixin hook-tab {
  margin-left: 0;
  .uk-active a {
    border-bottom: 0;
  }
  >li {
    padding-left: 0;
    margin-right: 16px;
  }
  > li > a {
    text-transform: none;
    font-size: 14px;
  }
  img {
    max-width: 88px;
  }
}

//offcanvas
$offcanvas-bar-background: $default-color-second;
$offcanvas-bar-padding-horizontal: 16px;
$offcanvas-bar-padding-vertical: 16px;
$offcanvas-bar-padding-horizontal-m: 16px;
$offcanvas-bar-padding-vertical-m: 16px;
$offcanvas-bar-width: 100%;
$offcanvas-bar-color-mode: $default-color-first;

@mixin hook-offcanvas-bar {
  @media screen and (min-width: 640px) {
    width: 320px;
  }
}
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400&amp;subset=cyrillic-ext');

$inherit: inherit;
$transparent: transparent;

$default-color-first: #262626;
$default-color-second: #ffffff;
$default-color-third: #767676;
$default-color-fourth: #b0b0b0;
$default-color-fifth: #a0a0a0;

$primary-color-first: #0D47A1;
$primary-color-second: #1E88E5;
$primary-color-third: #d0a863;

$stroke-color-first: #e0e0e0;
$bg-color-first: #F4F4F4;
$font-family: 'Roboto Slab', serif;


$font-weight-thin: 300;
$font-weight-regular: 400;

$font-size: 16px;

$gutter-small: 16px;
$gutter-medium: 24px;
$gutter: 32px;
$gutter-large: 64px;
// Name:            Spinner
// Description:     Component to create a loading spinner
//
// Component:       `uk-spinner`
//
// ========================================================================


// Variables
// ========================================================================

$spinner-size:                                   30px !default;
$spinner-stroke-width:                           1 !default;
$spinner-radius:                                 floor(($spinner-size - $spinner-stroke-width) / 2) !default; // Minus stroke width to prevent overflow clipping
$spinner-circumference:                          round(2 * 3.141 * $spinner-radius) !default;
$spinner-duration:                               1.4s !default;


/* ========================================================================
   Component: Spinner
 ========================================================================== */

/*
 * Adopts `uk-icon`
 */

.uk-spinner {
    @if(mixin-exists(hook-spinner)) {@include hook-spinner();}
}


/* SVG
 ========================================================================== */

.uk-spinner > * { animation: uk-spinner-rotate $spinner-duration linear infinite; }

@keyframes uk-spinner-rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

/*
 * Circle
 */

.uk-spinner > * > * {
    stroke-dasharray: $spinner-circumference;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: uk-spinner-dash $spinner-duration ease-in-out infinite;
    stroke-width: $spinner-stroke-width;
    stroke-linecap: round;
}

@keyframes uk-spinner-dash {
    0% { stroke-dashoffset: $spinner-circumference; }
    50% {
        stroke-dashoffset: $spinner-circumference/4;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: $spinner-circumference;
        transform:rotate(450deg);
    }
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-spinner-misc)) {@include hook-spinner-misc();}

// @mixin hook-spinner(){}
// @mixin hook-spinner-misc(){}

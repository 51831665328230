// Name:            Padding
// Description:     Utilities for padding
//
// Component:       `uk-padding`
//                  `uk-padding-large`
//                  `uk-padding-remove-*`
//
// ========================================================================


// Variables
// ========================================================================

$padding-padding:                                        $global-gutter !default;
$padding-padding-l:                                      $global-medium-gutter !default;

$padding-small-padding:                                  $global-small-gutter !default;

$padding-large-padding:                                  $global-gutter !default;
$padding-large-padding-l:                                $global-large-gutter !default;


/* ========================================================================
   Component: Padding
 ========================================================================== */

.uk-padding { padding: $padding-padding; }

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {

    .uk-padding { padding: $padding-padding-l; }

}


/* Small
 ========================================================================== */

.uk-padding-small { padding: $padding-small-padding; }


/* Large
 ========================================================================== */

.uk-padding-large { padding: $padding-large-padding; }

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {

    .uk-padding-large { padding: $padding-large-padding-l; }

}


/* Remove
 ========================================================================== */

.uk-padding-remove { padding: 0 !important; }
.uk-padding-remove-top { padding-top: 0 !important; }
.uk-padding-remove-bottom { padding-bottom: 0 !important; }
.uk-padding-remove-left { padding-left: 0 !important; }
.uk-padding-remove-right { padding-right: 0 !important; }

.uk-padding-remove-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.uk-padding-remove-horizontal {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


// Hooks
// ========================================================================

@if(mixin-exists(hook-padding-misc)) {@include hook-padding-misc();}

// @mixin hook-padding-misc(){}
